<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    width="45%"
    :title="`${isEdit ? '编辑' : '添加'}视频`"
    :visible.sync="isShow"
  >
    <div class="all-input">
       <div v-if="isEdit" style="width: 40% !important" class="item-input">
        <div class="label"><span class="red">*</span>名称：</div>
        <el-input v-model="formData.img_name" class="mini" placeholder="请输入昵称"></el-input>
      </div>
      <div style="width: 40% !important" class="item-input">
        <div class="label"><span class="red">*</span>选择工程：</div>
         <el-select
          class="mini"
          v-model="formData.p_id"
          placeholder="请选择工程"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="width: 40% !important" class="item-input">
        <div class="label"><span class="red">*</span>选择分类：</div>
         <el-cascader
           class="mini"
           v-model="formData.type_id"
          :options="optionsCas"
          :props="propsCas"
          clearable></el-cascader>
      </div>
      <div style="width: 40% !important" class="item-input">
        <div class="label"><span class="red">*</span>上传人员：</div>
        <div class="mini map" @click="openPe">{{ nickname }}</div>
      </div>
      <div :style="`width: ${isEdit ? '100' : '40'}% !important`" class="item-input">
        <div :style="!isEdit && 'width: 120px'" class="label"><span class="red">*</span>地理位置：</div>
        <div :style="`width: ${isEdit ? '100' : '85'}%`" class='map' @click="openMap">{{ address }}</div>
      </div>
      <div v-if="!isEdit" style="margin-top: 10px" class="item-input file">
        <div class="label"><span class="red">*</span>上传文件：</div>
        <el-upload
          ref="upload"
          accept=".mp4"
          action="https://jsonplaceholder.typicode.com/posts/"
          :file-list="fileList"
          :auto-upload="false"
          :on-change="getFile"
          :on-remove="delFile"
          :limit='1'
          :http-request="submitUpload"
        >
          <el-button slot="trigger" size="small" type="primary"
            >选取文件</el-button
          >
          <el-button
            style="margin-left: 10px"
            size="small"
            type="success"
            :loading="isLoad"
            @click="upload"
            >上传到服务器</el-button
          >
          <div class="el-upload__tip" slot="tip">只能上传mp4文件, 最多只能上传 1 个视频</div>
        </el-upload>
      </div>
    </div>

    <select-table :choice="onCallbackPe" ref="addS"></select-table>
    <select-map :localChange='onCallbackMarker' ref='map'></select-map>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { uploadFile, getProjetS } from '@/api/common'
import { debounce } from '@/utils/util'
import selectTable from '@/components/selectTable'
import selectMap from '@/components/selectMap'
import { getVSelect, addVideo, updVideo } from '@/api/monitor'
export default {
  name: 'addVideo',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {
    selectTable,
    selectMap,
  },
  data() {
    return {
      isShow: true, // 是否打开弹窗
      isLoad: false,
      options: [],
      optionsCas: [],
      propsCas: {
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'children',
      },
      fileList: [],
      formData: {
        type_id: '',
        user_id: '',
        p_id: '',
        address: '',
        lng: '',
        lat: '',
        img_url: '',
      },
      toastData: {
        type_id: '请选择分类',
        user_id: '请选择人员',
        p_id: '请选择工程',
        address: '请选择地理位置',
      },
      isEdit: false,
      files: [], // 上传文件列表
      fileIndex: 0, // 文件当前索引
      backup: [],
      list: [],
      nickname: '',
      address: '',
      VALUES: [],
      ID: '',
    }
  },
  created() {
    this._loadSelect()
    if (this.data.id) {
      this.isEdit = true
      const list = [{ name: this.data.img_name, url: this.data.img_url }]
      list.forEach((s) => {
        this.fileList.push({
          name: s.url,
          url: s.url,
        })
        this.files.push({
          status: 'success',
          name: s.url,
          url: s.url,
        })
        this.list.push(s.url)
      })
      this.nickname = this.data.nickname
      this.address = this.data.address
      this.formData = this.data
      this.formData.type_id = String(this.data.type_id)
    }
  },
  methods: {
    _loadSelect: function () {
      getProjetS().then((res) => {
        this.options = res.result
      })
      getVSelect().then(res => {
        const { result } = res
        this.optionsCas = result
        this.deepData(this.optionsCas)
      })
    },
    deepData: function(list) {
      for (let i = 0; i < list.length; i++) {
        list[i].id = String(list[i].id)
        if (list[i].children && list[i].children.length) this.deepData(list[i].children)
      }
    },
    openPe: function () {
      this.$refs.addS.open()
    },
    onCallbackPe: function (res) {
      this.formData.user_id = res.id
      this.nickname = res.nickname
    },
    openMap: function() {
      this.$refs.map.open()
    },

    // 获取当前坐标点
    onCallbackMarker: function({ address, location }) {
      this.address = address
      this.formData.address = address
      this.formData.lng = location.lng
      this.formData.lat = location.lat
    },

    upload: debounce(async function () {
      this.list = []
      this.files = this.files.map((s) => {
        if (s.raw) {
          s = s.raw
        }
        return s
      })

      this.backup = []
      if (this.files.length) this.isLoad = true
      for await (const f of this.files) {
        this.submitUpload(f)
      }
    }, 1000),
    submitUpload: function (f) {
      return new Promise((resolve) => {
        if (f.status && f.status === 'success') {
          this.backup.push(f)
          this.list.pust(f)
          this.fileIndex++
          if (this.fileIndex === this.files.length) {
            this.fileList = this.backup
            this.isLoad = false
          }
          resolve('success')
        } else {
          uploadFile(f).then((res) => {
            const { result } = res
            this.backup.push({
              name: f.name,
              url: result.filePath,
            })
            this.list.push({
              name: f.name,
              url: result.filePath,
            })
            this.fileIndex++
            if (this.fileIndex === this.files.length) {
              this.fileList = this.backup
              this.isLoad = false
            }
            resolve('success')
          })
        }
      })
    },

    // 获取文件
    getFile: function (file) {
      const uid = this.files.find((s) => {
        return s.uid === file.raw.uid
      })
      if (!uid) {
        this.files.push(file)
      }
      this.fileIndex = 0
    },

    // 获取删除后的文件列表
    delFile: function (file, files) {
      this.files = files
      const name = file.name

      this.list.forEach((s, i) => {
        if (s === name || s.name === name) {
          this.list.splice(i, 1)
        }
      })
    },

    close: function (isLoad) {
      this.$emit('close')
    },

    submit: function () {
      for (const key in this.toastData) {
        if (!this.formData[key]) {
          this.$toast(this.toastData[key])
          return false
        }
      }
      if (!this.list.length) {
        this.$toast('请上传视频')
        return false
      }

      const list = []
      this.list.forEach((s) => {
        list.push(s.url || s)
      })
      this.formData.img_url = list.join(',')
      if (typeof this.formData.type_id === 'object') {
        this.formData.type_id = this.formData.type_id[this.formData.type_id.length - 1]
      }
      if (!this.isEdit) this.save()
      else this.edit()
    },

    save: function () {
      addVideo(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.$emit('close', 'success')
        }
      })
    },

    edit: function () {
      updVideo(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.$emit('close', 'success')
        }
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.all-input {
  justify-content: space-between;

  .file {
    align-items: flex-start;
  }
}
</style>
