<template>
  <div style="width: 100%; height: 100%">
    <div class="navbar">
      <div class="switch">
        <i
          @click="switchType"
          :class="isShowFile ? 'el-icon-menu' : 'el-icon-s-unfold'"
        ></i>
      </div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item @click.native="_loadData()" class="item-br"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item
          @click.native="gotoNext(s)"
          class="item-br"
          v-for="(s, i) in navbar"
          :key="i"
          >{{ s.name }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <div class="search">
      <el-button @click="open" size="small" type="primary">添加视频</el-button>
      <div class="all-search">
        <div class="item-search">
          <span class="key blod">文件夹：</span>
          <el-input
            class="value"
            v-model="name"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <el-button @click="search" size="small" type="primary">搜索</el-button>
      </div>
    </div>

    <div class="content">
      <div v-if="isShowFile" class="file">
        <div
          @dblclick="gotoNext(item)"
          @click="selectFile(item, index)"
          v-for="(item, index) in files"
          :key="index"
          :class="['item-file', index === fileIndex && 'select-file']"
        >
          <img :src="item.url || require('@/assets/file/file.png')" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>

      <div v-else class="table">
        <el-table
          ref="multipleTable"
          :data="files"
          tooltip-effect="dark"
          style="width: 100%"
          border
          highlight-current-row
        >
          <el-table-column prop="name" label="名称" width="200" >
            <template slot-scope="scope">
              <div @dblclick="gotoNext(scope.row)" class="file-name">
                <img :src="scope.row.url || require('@/assets/file/file.png')" alt="">
                <span>{{ scope.row.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类型" width="200"></el-table-column>
          <el-table-column prop="p_id" label="工程名称" width="240"></el-table-column>
          <el-table-column prop="user_id" label="上传人员" width="240"></el-table-column>
          <el-table-column prop="address" label="上传地点" width="240"></el-table-column>
          <el-table-column prop="create_at" label="创建时间" width="240"></el-table-column>
          <el-table-column prop="date" label="操作">
            <template slot-scope="scope">
              <div>
                <el-button  @click="edit(scope.row)" size="mini">编辑</el-button>
                <el-button @click="remove(scope.row)" size='mini' type="danger">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <show-vid ref="showVID"></show-vid>
    <add-video :data='vdata' @close='onCallbackClose' v-if="isShowAdd"></add-video>
    <edit-file :doneChange='onCallbackDone' ref='editF'></edit-file>
  </div>
</template>

<script>
import showVid from './components/showVID'
import addVideo from './components/addVideo'
import editFile from '../components/editFile'
import { getVFiles, getVideoDetail, delVideo, delVFile, updVFile } from '@/api/monitor'
export default {
  name: 'VideoManageVideoList',
  components: {
    showVid,
    addVideo,
    editFile,
  },
  data() {
    return {
      name: '',
      files: [], // 文件列表
      fileIndex: null, // 选中的文件
      navbar: [], // 面包屑
      isShowFile: false, // true: 文件夹模式  false: 表格模式
      isShowAdd: false,
      vdata: {},
      currentID: '',
    }
  },
  created() {
    this._loadData()
  },
  // beforeDestroy() {
  //   this.currentID = 0
  // },
  methods: {
    _loadData: function (id = 0) {
      this.fileIndex = null
      !id && (this.navbar = [])
      this.currentID = id
      getVFiles(id).then((res) => {
        const { result } = res
        let list = []
        list = result.folder
        if (result.files) {
          result.files.forEach((s) => {
            s.video = s.url
            s.url = s.url + '?vframe/jpg/offset/1'
            list.push(s)
          })
        }
        this.files = list
      })
    },
    selectFile: function (item, index) {
      this.fileIndex = index
    },
    gotoNext: function ({ id = '', name, video = '' }) {
      if (!video) {
        // 文件
        this.navbar.push({ id, name })

        const index = this.navbar.findIndex((s) => s.id === id)
        index > -1 && (this.navbar = this.navbar.slice(0, index + 1))
        this._loadData(id)
      } else {
        // 图片 or 视频
        this.$refs.showVID.open({
          src: video,
          title: name,
        })
      }
    },
    search: function () {
      if (!this.name) {
        this._loadData()
        return false
      }
      const re = new RegExp(this.name)
      const list = []
      this.files.forEach((s) => {
        if (re.test(s.name)) {
          list.push(s)
        }
      })
      this.files = list
    },
    switchType: function () {
      this.isShowFile = !this.isShowFile
    },

    open: function() {
      this.vdata = {}
      this.isShowAdd = true
    },

    edit: function({ id, url = '', name }) {
      if (url) {
        getVideoDetail(id).then(res => {
          this.vdata = res.result
          this.isShowAdd = true
        })
      } else {
        this.$refs.editF.open({ name, id })
      }
    },
    remove: function({ id, url = '' }) {
      if (url) {
        delVideo(id).then(res => {
          this.$toast(res.message)
          if (res.status === 200) {
            this._loadData(this.currentID)
          }
        })
      } else {
        delVFile(id).then(res => {
          this.$toast(res.message)
          if (res.status === 200) {
            this._loadData(this.currentID)
          }
        })
      }
    },
    onCallbackClose: function(event) {
      this.isShowAdd = false
      event && this._loadData(this.currentID)
    },
    onCallbackDone: function(data) {
      updVFile(data).then(res => {
        this.$toast(res.message)
        if (res.status === 200) {
          this._loadData(this.currentID)
        }
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.navbar {
  width: 100%;
  display: flex;
  height: 46px;
  align-items: center;
  border-bottom: 1px solid #DCDEE2;

  .switch {
    margin-right: 15px;
    cursor: pointer;
  }
}

.search {
  display: flex;
  width: 100%;
  height: 64px;
  align-items: center;
  border-bottom: 1px solid #DCDEE2;
  overflow: hidden;

  .all-search {
    display: flex;
    align-items: center;
    margin-left: 32px;

    .item-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 30px;

      .key {
        font-size: $Fontsize;
        color: #515A6E;
      }

      .value {
        width: 332px;
      }

      .date-value {
        width: 332px;
      }
    }
  }
}

.content {
  width: 100%;
  height: 84%;
  margin-top 16px;
  .file {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .item-file {
      width: 123px;
      height: 127px;
      margin: 16px;
      margin-left: 24px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      img {
        width: 54px;
        height: 54px;
      }

      span {
        width: 85%;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #515A6E;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 14px;
        text-align: center;
      }
    }

    .select-file {
      background-color: #F6F7FA;
    }
  }
  .table {
    .file-name {
      display flex;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-items: center;
      // cursor pointer
      img {
        width 24px;
        height 24px;
        margin-right 5px;
      }
      span {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #606266;
      }
    }
  }
}

.item-br {
  cursor: pointer;
}
</style>
