<template>
  <el-dialog
   :close-on-click-modal='false'
   :close-on-press-escape='false'
   :destroy-on-close='true'
   :show-close='true'
    @close='onCallbackClose'
    width="45%" :title="title" :visible.sync="isShow">
    <video style="width: 100%; height: 500px" muted controls :src="src"></video>
  </el-dialog>
</template>

<script>
export default {
  name: 'showIMG',
  data() {
    return {
      isShow: false, // 是否打开弹窗
      src: '',
      title: '',
    }
  },
  methods: {
    open: function ({ src, title }) {
      this.src = src
      this.title = title
      this.isShow = true
    },
    onCallbackClose: function () {
      this.isShow = false
    },
  },
}
</script>

<style lang="stylus" scoped>
</style>
